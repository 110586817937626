import { Link } from "react-router-dom";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";
import Button from "common/components/Button/Button";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import useAuth from "common/hooks/useAuth";
import Status from "common/components/Status/Status";

const containerStyle =
  "flex px-4 py-4 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all overflow-hidden";

function UserVerificationItem({ item, onDelete }: any) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const editLink = `/user-verifications/${item.id}`;

  return (
    <div
      className={`${containerStyle}  items-start lg:items-stretch flex-col lg:flex-row`}
    >
      <Link to={editLink} className="w-full">
        <div className="responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4">
          <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
            <div className="absolute -top-2 -left-2 w-full h-full rounded-lg overflow-hidden">
              <ImageComponent
                image={
                  item.user.avatar
                    ? ImageFromStrapiMedia(item.user.avatar)?.uri
                    : undefined
                }
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div className="flex flex-col xl:flex-row w-full gap-2">
              <div className="flex flex-col flex-1 gap-1">
                <div className="flex flex-row flex-wrap items-center gap-4">
                  <h4 className="text-xl font-bold text-primary leading-6">
                    {item.user.username}
                  </h4>
                  <h4 className="text-lg text-primary leading-6">
                    {`(${item.user.email})`}
                  </h4>
                </div>
                <h4 className="text-lg text-primary leading-6">
                  {`${item.user.firstname} ${item.user.lastname}`}
                </h4>
              </div>
            </div>

            <div className="responsive-flex-lg mt-1" />

            {/* foot */}
            <div className="flex flex-col mt-2 gap-2 pt-1 border-t border-slate-200">
              <p>
                <b>Type</b> :{" "}
                {item.user.isCompany ? "Entreprise" : "Particulier"}
              </p>
            </div>
          </div>
        </div>
      </Link>

      {/* right */}
      <div className="flex flex-col justify-between text-xs w-full lg:border-l lg:pl-4 lg:w-[30em] lg:border-slate-150 gap-2">
        <div className="self-end">
          <Status status={item.status} />
        </div>

        {user?.isSuperAdmin && (
          <div className="flex w-full gap-1 justify-end">
            <Button
              type="error-line"
              icon="TrashIcon"
              compact
              onClick={() => onDelete(item?.id)}
              confirmMessage={t("actions.confirmDelete")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default UserVerificationItem;
