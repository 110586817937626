import {
  ArrowUpCircleIcon,
  CheckIcon,
  ListBulletIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import useList from "common/hooks/useList";
import UserSearch from "components/search/UserSearch";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Transaction } from "types/Transaction";
import useRouter from "common/hooks/use-router";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import CategorySelect from "components/select/CategorySelect/CategorySelect";
import useTransaction from "common/hooks/useTransaction";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import TabBlockItem from "common/components/TabBlockItem/TabBlockItem";
import AlertsContext from "common/providers/alerts";
import { MdCurrencyExchange, MdPriceCheck } from "react-icons/md";
import { PiFlagCheckeredFill } from "react-icons/pi";
import { FaExchangeAlt } from "react-icons/fa";
import TransactionItem from "./TransactionItem";

function Transactions() {
  const { t } = useTranslation();

  const { setAlert } = useContext(AlertsContext);
  const { navigate } = useRouter();

  const { transactionsNumber, refreshTransactionsNumber } = useTransaction();

  const {
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<Transaction>("transactions", {
    defaultSorts: ["updatedAt:DESC", "createdAt:DESC"],
    populate: [
      "content",
      "content.images",
      "seller",
      "buyer",
      "finalMeetingPlace",
    ],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const statusList = useMemo(() => {
    return [
      {
        id: "",
        name: t("status.all"),
        count: transactionsNumber?.all || 0,
        icon: ListBulletIcon,
        color: "primary",
      },
      {
        id: "opened",
        name: t("status.opened"),
        count: transactionsNumber?.opened || 0,
        icon: ArrowUpCircleIcon,
        color: "blue",
      },
      {
        id: "price",
        name: t("status.price"),
        count: transactionsNumber?.price || 0,
        icon: MdPriceCheck,
        color: "teal",
      },
      {
        id: "meeting",
        name: t("status.meeting"),
        count: transactionsNumber?.meeting || 0,
        icon: PencilIcon,
        color: "yellow",
      },
      {
        id: "RDVS",
        name: t("status.on_place_both"),
        count: transactionsNumber?.meeting || 0,
        icon: PiFlagCheckeredFill,
        color: "red",
      },
      {
        id: "trade",
        name: t("status.trade_validated"),
        count: transactionsNumber?.meeting || 0,
        icon: FaExchangeAlt,
        color: "green",
      },
      {
        id: "review",
        name: t("status.reviewed_both"),
        count: transactionsNumber?.meeting || 0,
        icon: CheckIcon,
        color: "darkgreen",
      },
    ];
  }, [t, transactionsNumber]);

  const columns = useMemo(
    () => [
      {
        Header: t("forms.buyer"),
        filterName: "buyer][username]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("forms.seller"),
        filterName: "seller][username]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("forms.category"),
        filterName: "content][category]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <CategorySelect isFilter Icon={WorkSiteIcon} {...props} />
        ),
      },
      {
        Header: t("forms.content"),
        filterName: "content][title]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
      },
    ],
    [t]
  );

  useEffect(() => {
    refreshTransactionsNumber();
  }, [refreshTransactionsNumber]);

  const handleNavigate = (id: any) => {
    navigate(`/transactions/edit/${id}`);
  };

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.transactions")}
        subTitle={t("titleLegends.transactions")}
        Icon={MdCurrencyExchange}
        noAdd
        isBeforeFilters
        filters={filters}
        setFilter={setFilter}
        filterName="status$containsi"
        FilterComponent={(props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={statusList}
            horizontal
            noGap
            {...props}
          />
        )}
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={TransactionItem}
        onDelete={() => {}}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Transactions;
