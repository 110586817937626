import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/fr";

import useItem from "common/hooks/useItem";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import PageLoading from "common/components/PageLoading/PageLoading";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import Status from "common/components/Status/Status";
import AlertsContext from "common/providers/alerts";
import { Transaction } from "types/Transaction";
import formatPrice from "utils/formatPrice";
import UserCard from "components/UserCard/UserCard";
import Icon from "components/Icon/Icon";
import HeaderButtons from "common/components/DetailHeader/HeaderButtons";
import leadZeros from "utils/leadZeros";
import rdvSecuriseImg from "../../../public/images/handshake-light.png";

function TransactionDetails() {
  const router = useRouter();
  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const [errorMessage] = useState("");

  const { item, isFetching, error }: any = useItem<Transaction>(
    "transactions",
    id,
    {
      populate: [
        "content",
        "content.category",
        "content.category.logo",
        "buyer",
        "buyer.avatar",
        "buyer.companyLogo",
        "seller",
        "seller.avatar",
        "seller.companyLogo",
        "content.images",
        "finalMeetingPlace",
      ],
    }
  );

  function setFullScreenImg(image: string) {
    throw new Error("Function not implemented.");
  }

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    } else if (errorMessage) {
      setAlert(errorMessage, "danger");
    }
  }, [error, errorMessage, setAlert]);

  const {
    content,
    buyer,
    seller,
    status,
    // blocked,
    // finalExchangeOffer,
    finalMeetingPlace,
    finalPrice,
    startMeetingDate,
    endMeetingDate,
    updatedAt,
  } = (item as Transaction) || {};

  const HeaderSubTitle = useCallback(
    (mainText: string, subText?: string) => (
      <>
        <span className="font-bold">{mainText}</span>
        {subText && <span> | {subText}</span>}
      </>
    ),
    []
  );

  const TransactionHeaderInfos = useMemo(
    () => (
      <div className="w-full">
        <div className="flex items-center text-xs gap-1 field-title">
          <Icon name="ClockIcon" className="w-4 h-4" />
          {dayjs(updatedAt).fromNow()}
        </div>
      </div>
    ),
    [updatedAt]
  );

  if (isFetching || !item) {
    return <PageLoading />;
  }
  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <DetailHeader
        backLink="/transactions"
        title={t("titleLegends.transaction", { id: leadZeros(id) })}
        Status={
          <div className="flex gap-2 flex-wrap">
            <Status status={status} />
          </div>
        }
        subtitleIcon="MdCurrencyExchange"
        TitleIcon={
          <div className="hidden lg:flex items-center justify-center border border-primary w-14 min-w-[3.5rem] h-14 rounded-lg">
            <Icon name="MdCurrencyExchange" className="text-primary w-7 h-7" />
          </div>
        }
        SubTitle={HeaderSubTitle(content?.category?.name)}
        HeaderInfos={TransactionHeaderInfos}
        HeaderButtons={<HeaderButtons editUrl={`/transactions/edit/${id}`} />}
      />

      <div className="responsive-flex gap-2 mt-2">
        <div className="white-box responsive-flex flex-col justify-between w-full lg:basis-1/2">
          {/* Column 1 */}
          <div className="flex flex-col gap-1">
            <SectionTitle
              title={t("forms.priceValidated")}
              icon="WalletIcon"
              className="text-secondary"
            />
            <div className="responsive-flex flex-row">
              <span className="font-bold text-xl pl-4">
                {formatPrice(finalPrice)}
              </span>
            </div>
            <SectionTitle
              title={t("forms.meetingTime")}
              icon="BookmarkIcon"
              className="text-secondary mt-2"
            />
            <div className="responsive-flex flex-row">
              <span className="font-bold text-xl py-1 pl-4">
                {startMeetingDate && endMeetingDate
                  ? `Le ${dayjs(startMeetingDate).format("LL")} de ${dayjs(
                      startMeetingDate
                    ).format("H:mm")} à ${dayjs(endMeetingDate).format("H:mm")}`
                  : t("transactions.noMeetingDate")}
              </span>
            </div>
            <SectionTitle
              title={t("forms.meetingPlace")}
              icon="BookmarkIcon"
              className="text-secondary mt-2"
            />
            {finalMeetingPlace ? (
              <div className="responsive-flex flex-col">
                {finalMeetingPlace.addressTitle && (
                  <div className="responsive-flex flex-row">
                    <span className="font-bold text-xl py-1 pl-4">
                      {finalMeetingPlace.addressTitle}
                    </span>
                  </div>
                )}
                {finalMeetingPlace.street && (
                  <div className="responsive-flex flex-row">
                    <span className="font-medium text-xl pl-4">
                      {`${finalMeetingPlace.street}`}
                    </span>
                  </div>
                )}
                {finalMeetingPlace.postalCode && finalMeetingPlace.city && (
                  <div className="responsive-flex flex-row">
                    <span className="font-medium text-xl pl-4">
                      {`${finalMeetingPlace.postalCode} ${finalMeetingPlace.city}`}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="responsive-flex flex-row">
                <span className="font-bold text-xl py-1 pl-4">
                  {t("transactions.noMeetingPlace")}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="responsive-flex gap-2 flex-col w-full lg:basis-1/2">
          <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
            <UserCard user={buyer} title={t("forms.buyer")} />
          </div>
          <div className="white-box responsive-flex gap-4 flex-col w-full lg:basis-1/2">
            <UserCard user={seller} title={t("forms.seller")} />
          </div>
        </div>
      </div>
      <div className="responsive-flex gap-2 mt-2">
        <div className="flex-1">
          <div className="flex flex-col gap-2 w-full">
            {/* Description */}
            <div className="responsive-flex flex flex-col lg:flex-row gap-4 items-start">
              {/* Column 1 */}
              <div className="white-box responsive-flex gap-2 flex-col w-full">
                <div className="flex flex-row gap-4">
                  <div className="basis-1/3">
                    <SectionTitle
                      title={t("forms.title")}
                      icon="WalletIcon"
                      className="text-secondary mt-2"
                    />
                    <div className="responsive-flex flex-row">
                      <span className="font-bold text-xl py-1 pl-4">
                        {content.title}
                      </span>
                    </div>
                  </div>
                  <div className="basis-1/3">
                    <SectionTitle
                      title={t("forms.price")}
                      icon="WalletIcon"
                      className="text-secondary mt-2"
                    />
                    <div className="responsive-flex flex-row">
                      <span className="font-bold text-xl py-1 pl-4">
                        {formatPrice(content.price)}
                      </span>
                    </div>
                  </div>
                  <div className="basis-1/3 text-center">
                    <div className="flex flex-row justify-end gap-4 text-[#504e70]">
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          content.useSafeMeetingPlace
                            ? " shadow-md"
                            : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <Icon name="CheckBadgeSolid" className="w-9 h-9" />
                        </div>
                        RDV sécurisé
                      </div>
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          content.canExchange
                            ? " shadow-md"
                            : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <ImageComponent image={rdvSecuriseImg} />
                        </div>
                        Troc accepté
                      </div>
                      <div
                        className={`flex flex-col basis-1/3 items-center text-sm border rounded-md p-2 ${
                          content.isChapChap
                            ? " shadow-md"
                            : "opacity-20 shadow-xs"
                        }`}
                      >
                        <div className="max-h-9 max-w-9">
                          <Icon name="FireIcon" className="w-9 h-9" />
                        </div>
                        Chap chap !
                      </div>
                    </div>
                  </div>
                </div>
                <SectionTitle
                  title={t("forms.description")}
                  icon="BookmarkIcon"
                  className="text-secondary mt-2"
                />
                <div className="responsive-flex py-2 px-4 flex-col border rounded">
                  {content.description ? (
                    content.description
                      .split("\n")
                      .map((p: any, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={`p-${index}`} className="mb-4">
                          {p}
                        </p>
                      ))
                  ) : (
                    <p>{t("forms.noContent")}</p>
                  )}
                </div>
                <SectionTitle
                  title={t("forms.address")}
                  icon="MapPinIcon"
                  className="text-secondary mt-2"
                />
                <div className="responsive-flex flex-row">
                  <span className="text-md py-1 pl-4">{content.address}</span>
                </div>
              </div>
            </div>
            {/* Images */}
            <div className="responsive-flex white-box">
              <div className="responsive-flex gap-4 flex-col w-full">
                <div className="responsive-flex gap-4 w-full flex-row flex-wrap justify-center">
                  {content.images?.map((image, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`image-${index}`}
                      className="rounded-lg overflow-hidden aspect-square h-[250px] md:h-[180px]"
                      onClick={() => {
                        setFullScreenImg(image);
                      }}
                    >
                      <ImageComponent
                        image={ImageFromStrapiMedia(image)?.uri}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetails;
