import {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";

import {
  ArrowUpCircleIcon,
  CalendarDaysIcon,
  ExclamationCircleIcon,
  ListBulletIcon,
  ArchiveBoxIcon,
  ChatBubbleLeftRightIcon,
  CheckIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

import { Content } from "types/Content";
import Header from "common/components/Header/Header";
import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import useList from "common/hooks/useList";
import FilteredList from "common/components/Table/FilteredList";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import TabBlockItem from "common/components/TabBlockItem/TabBlockItem";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";

import AlertsContext from "common/providers/alerts";
import CategorySelect from "components/select/CategorySelect/CategorySelect";
import useAuth from "common/hooks/useAuth";
import UserSearch from "components/search/UserSearch";
import useContent from "common/hooks/useContent";
import ContentItem from "./ContentItem";

function Contents() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const { contentsNumber, refreshContentsNumber } = useContent();

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error: errorList1,
  } = useList<Content>("contents", {
    defaultSorts: ["updatedAt:DESC", "createdAt:DESC"],
    populate: ["category", "category.logo", "images", "author"],
  });

  useEffect(() => {
    if (errorList1) {
      setAlert(errorList1, "danger");
    }
  }, [errorList1, setAlert]);

  const statusList = useMemo(() => {
    return [
      {
        id: "",
        name: t("status.all"),
        count: contentsNumber?.all || 0,
        icon: ListBulletIcon,
        color: "primary",
      },
      {
        id: "draft",
        name: t("status.draft"),
        count: contentsNumber?.draft || 0,
        icon: PencilIcon,
        color: "yellow",
      },
      {
        id: "submitted",
        name: t("status.submitted"),
        count: contentsNumber?.submitted || 0,
        icon: ArrowUpCircleIcon,
        color: "blue",
      },
      {
        id: "published",
        name: t("status.published"),
        count: contentsNumber?.published || 0,
        icon: CheckIcon,
        color: "green",
      },
      {
        id: "rejected",
        name: t("status.rejected"),
        count: contentsNumber?.rejected || 0,
        icon: ExclamationCircleIcon,
        color: "red",
      },
      {
        id: "negociated",
        name: t("status.negociated"),
        count: contentsNumber?.negociated || 0,
        icon: ChatBubbleLeftRightIcon,
        color: "darkgreen",
      },
      {
        id: "closed",
        name: t("status.closed"),
        count: (contentsNumber?.closed ?? 0) + (contentsNumber?.sold ?? 0) || 0,
        icon: ArchiveBoxIcon,
        color: "slate",
      },
    ];
  }, [t, contentsNumber]);

  useEffect(() => {
    refreshContentsNumber();
  }, [refreshContentsNumber]);

  const { removeItem } = useItem<Content>("contents", "");

  const { navigate } = useRouter();
  const { user } = useAuth();

  const handleNavigate = (id: any) => {
    navigate(`/contents/edit/${id}`);
  };

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const columns = useMemo(
    () => [
      /* {
        Header: t("filters.status"),
        headerClasses: "hidden",
        columnClasses: "w-full mb-[-8px]", // mb-[-12px]
        filterName: "status$contains",
        filterComponent: (props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={statusList}
            horizontal
            noGap
            {...props}
          />
        ),
      }, */
      {
        Header: t("forms.title"),
        filterName: "title$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("forms.author"),
        filterName: "author][username]$containsi",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => <UserSearch isFilter {...props} />,
      },
      {
        Header: t("forms.category"),
        filterName: "category][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <CategorySelect isFilter Icon={WorkSiteIcon} {...props} />
        ),
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.contents")}
        subTitle={t("titleLegends.contents")}
        btnTitle={t("newItem.content")}
        btnSubtitle={t("newItem.contentLegend")}
        Icon={CalendarDaysIcon}
        addLink="/contents/add"
        noAdd={!user?.isJuloaAdmin}
        isBeforeFilters
        filters={filters}
        setFilter={setFilter}
        filterName="status$containsi"
        FilterComponent={(props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={statusList}
            horizontal
            noGap
            {...props}
          />
        )}
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={ContentItem}
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Contents;
